import React, { useState } from "react"
import EduSection from "./EduSection"
import {
  EDUCATION_2020,
  EDUCATION_2021,
} from "../../content/education/education.const"
import "./EduButtons.scss"
import "./EduSections.scss"

const EduBookmarks = ({ page }) => {
  let project
  page.project === "2020" && (project = EDUCATION_2020)
  page.project === "2021" && (project = EDUCATION_2021)
  const [btn, btnToggle] = useState(1)

  const btnActive = nmbr => {
    btnToggle(nmbr)
  }

  return (
    <>
      <nav className="bookmarks">
        {project.sections !== undefined &&
          project.sections.map((item, index) => {
            return (
              <button
                key={item.title}
                onClick={() => btnActive(index + 1)}
                className={
                  "bookmark__btn " +
                  (btn === index + 1 ? "bookmark__btn--active" : "")
                }
              >
                {item.title}
              </button>
            )
          })}
      </nav>
      <>
        {project.sections !== undefined &&
          project.sections.map((item, index) => {
            return (
              <EduSection
                name={
                  "edu-article " +
                  (btn === index + 1 ? "edu-article--active" : "")
                }
                key={item.title}
                title="Wstęp"
                page={page.nmbr}
                section={item.source}
                project={page.project}
              />
            )
          })}
      </>
    </>
  )
}

export default EduBookmarks
