import React from "react"
import EduHeader from "../components/EduPages/EduHeader"
import EduBookmarks from "../components/EduPages/EduBookmarks"
import EduFooter from "../components/EduPages/EduFooter"
import Footer from "../components/Footers/Footer"
import "../components/EduPages/EduTitles.scss"

const EduPage = ({ pageContext: { page } }) => (
  <>
    <EduHeader page={page} />
    <main id={"EduMain" + page.project} className="edu-main">
      <article className="article">
        <h1 className="edu-page__title">{page.title}</h1>
      </article>
      <EduBookmarks page={page} />
      <EduFooter project={page.project} />
    </main>
    <Footer project={page.project} />
  </>
)

export default EduPage
