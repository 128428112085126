import React from "react"
import EduElems from "../../templates/edu-elems"
import "./EduSections.scss"

const EduSection = ({ name, title, page, section, project }) => {
  const data = require(`../../content/education/${project}/${page}/${section}.json`)

  return (
    <article className={name}>
      {/* <h2>{title}</h2> */}
      <EduElems data={data} />
    </article>
  )
}

export default EduSection
